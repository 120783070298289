// @codingStandardsIgnoreFile
'use strict'

const common = require('core/common')
const Modal = require('core/modules/modal')

const cart = {
  supplierId: null,
  init: function () {
    const self = this
    const cartElement = document.querySelector('.cart')
    self.supplierId = cartElement.getAttribute('data-supplier-id')

    document.addEventListener('submit', function (e) {
      const el = e.target

      if (el.matches('form[name=checkOffer]')) {
        e.preventDefault()
        e.stopPropagation()

        document.body.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
        self.loading('start')

        const offerId = el.querySelector('input[name="checkOffer[offerId]"]').value
        const productId = el.querySelector('input[name="checkOffer[productId]"]').value
        const requestUri = el.querySelector('input[name="checkOffer[requestUri]"]').value
        const xhr = common.createXhr('POST', cartElement.getAttribute('data-url-add'))

        xhr.addEventListener('readystatechange', function () {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            self.updateCart(JSON.parse(xhr.response))
          }
        })
        xhr.onerror = function () {
          common.showFlashMessage(cartElement.getAttribute('data-error-message'))
        }
        xhr.send('offerId=' + offerId + '&productId=' + productId + '&requestUri=' + encodeURIComponent(requestUri))
      }
    })

    self.handleRemoveItem()
    self.handleEmptyCart()
    self.handleDisplayCart()
    self.handlePaymentTermsModal()

    return this
  },

  updateCart: function (data) {
    const self = this

    document.querySelectorAll('.cart__row:not(.cart__row--template)').forEach(function (el) {
      el.parentNode.removeChild(el)
    })

    let i = 1
    const cartLength = Object.keys(data.cart).length
    for (const offer in data.cart) {
      if (Object.prototype.hasOwnProperty.call(data.cart, offer)) {
        const rowEl = document.querySelector('.cart__row--template').cloneNode(true)
        document.querySelector('.cart').insertBefore(rowEl, document.querySelector('.cart__accommodation-total'))
        rowEl.classList.remove('cart__row--template')
        rowEl.removeAttribute('hidden')

        if (i++ === cartLength) {
          rowEl.classList.add('cart__row--last')
        }

        rowEl.setAttribute('data-offer-id', data.cart[offer].offer.id)
        rowEl.querySelector('.title__name').innerHTML = data.cart[offer].product.name

        for (let k = 0; k < data.cart[offer].product.stars; k++) {
          rowEl.querySelector('.title__stars').innerHTML += '&#9733; '
        }

        rowEl.querySelector('.name__accommodation').innerHTML = data.cart[offer].accommodation.name

        const amount = common.formatCurrency(data.cart[offer].offer.amount)
        const sellingAmount = common.formatCurrency(data.cart[offer].offer.sellingAmount)
        if (amount > sellingAmount) {
          rowEl.querySelector('.amount__initial').innerHTML = common.formatCurrency(data.cart[offer].offer.amount)
          rowEl.querySelector('.amount__discount').innerHTML = '-' + data.cart[offer].offer.discount + '%'
        }

        rowEl.querySelector('.price__discount').dataset.discount = data.cart[offer].offer.discount
        rowEl.querySelector('.amount__final').innerHTML = sellingAmount
        rowEl.querySelector('.from').innerHTML = common.formatDate(data.cart[offer].offer.checkInDate)
        rowEl.querySelector('.to').innerHTML = common.formatDate(data.cart[offer].offer.checkOutDate)
      }
    }

    if (data.totalFeesAmount !== null) {
      document.querySelector('.cart .cart__fees .amount').innerHTML = common.formatCurrency(data.totalFeesAmount)
    } else {
      document.querySelector('.cart .cart__fees .amount').innerHTML = ''
    }

    if (data.feesLabel !== null) {
      document.querySelector('.cart .cart__fees .fees-label').innerHTML = data.feesLabel
    } else {
      document.querySelector('.cart .cart__fees .fees-label').innerHTML = ''
    }

    if (data.insuranceEstimatedAmount !== null) {
      document.querySelector('.cart .cart__insurance .amount').innerHTML = common.formatCurrency(data.insuranceEstimatedAmount)
      document.querySelector('.cart').dataset.insurance = '1'
      document.querySelector('.cart .total__row--with-insurance .amount').innerHTML = common.formatCurrency(data.totalAmount + data.insuranceEstimatedAmount)
    } else {
      document.querySelector('.cart .cart__insurance .amount').innerHTML = ''
      document.querySelector('.cart').dataset.insurance = '0'
      document.querySelector('.cart .total__row--with-insurance .amount').innerHTML = common.formatCurrency(0)
    }

    if (
      data.paymentTerms['cb-2x'] ||
      data.paymentTerms['cb-3x'] ||
      data.pledgPaymentTerms['cb-4x'] ||
      data.paymentTerms['3x-1e'] ||
      data.paymentTerms['4x-1e']
    ) {
      document.querySelector('.cart .payment-terms__info--available').classList.add('payment-terms__info--shown')
      document.querySelector('.cart .payment-terms__info--not-available').classList.remove('payment-terms__info--shown')
    } else {
      document.querySelector('.cart .payment-terms__info--available').classList.remove('payment-terms__info--shown')
      document.querySelector('.cart .payment-terms__info--not-available').classList.add('payment-terms__info--shown')
    }

    document.querySelector('.cart .cart__accommodation-total .amount').innerHTML = common.formatCurrency(data.totalAccommodationAmount)
    document.querySelector('.cart .cart__total .amount').innerHTML = common.formatCurrency(data.totalAmount)
    document.getElementById('cart-quantity').innerHTML = data.totalQuantity

    if (data.result === false) {
      common.showFlashMessage(data.message)
    }

    if (data.supplierId !== self.supplierId) {
      self.handleSupplierChange(data.supplierId)
    }

    self.updatePaymentTermsModal(data)

    self.loading('end')
  },

  handleRemoveItem: function () {
    const self = this

    document.addEventListener('click', function (e) {
      const el = e.target

      if (el.matches('.cart__row .remove')) {
        self.empty()
      }
    })
  },

  handleEmptyCart: function () {
    const self = this
    document.querySelector('.cart .btn-clear').addEventListener('click', function () {
      self.empty()
    })
  },

  handleDisplayCart: function () {
    const iconCart = document.querySelector('.icon-cart')

    if (iconCart) {
      iconCart.addEventListener('click', function () {
        document.querySelector('.cart').classList.toggle('lcv-hidden')
      })
    }
  },

  empty: function () {
    const self = this
    self.loading('start')

    const cartElement = document.querySelector('.cart')
    const xhr = common.createXhr('POST', cartElement.getAttribute('data-url-empty'))

    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        self.updateCart(JSON.parse(xhr.response))
      }
    })
    xhr.onerror = function () {
      common.showFlashMessage(cartElement.getAttribute('data-error-message'))
    }
    xhr.send()
  },

  loading: function (type) {
    if (type === 'start') {
      document.querySelector('.cart-loading').style.display = 'block'
      document.getElementById('cart-quantity').style.display = 'none'
    } else {
      document.querySelector('.cart-loading').style.display = 'none'
      document.getElementById('cart-quantity').style.display = 'block'
    }
  },

  handleSupplierChange: function (newSupplierId) {
    const self = this
    const cartElement = document.querySelector('.cart')

    self.supplierId = newSupplierId

    document.querySelectorAll('.accommodation-details').forEach(function (el) {
      const supplierId = parseInt(el.getAttribute('data-supplier-id'))
      const submitButton = el.querySelector('button[type="submit"]')
      const supplierError = el.querySelector('.supplier-error')
      const detailWrapper = el.querySelector('.details-wrapper')
      const showProperties = el.querySelector('.show-properties')

      if (self.supplierId !== null && supplierId !== self.supplierId) {
        if (submitButton !== null) {
          submitButton.disabled = true
          submitButton.classList.add('disabled')
        }

        if (supplierError !== null) {
          supplierError.textContent = cartElement.getAttribute('data-supplier-error-message')
          supplierError.style.display = 'block'
        }

        if (showProperties !== null) {
          showProperties.style.display = 'none'
        }

        if (detailWrapper !== null) {
          detailWrapper.style.opacity = 0.3
        }
      } else {
        if (submitButton !== null) {
          submitButton.disabled = false
          submitButton.classList.remove('disabled')
        }

        if (supplierError !== null) {
          supplierError.textContent = ''
          supplierError.style.display = 'none'
        }

        if (showProperties !== null) {
          showProperties.style.display = 'inline-block'
        }

        if (detailWrapper !== null) {
          detailWrapper.style.opacity = 1
        }
      }
    })
  },

  handlePaymentTermsModal: function () {
    if (document.querySelector('.payment-terms__link') != null) {
      document.querySelector('.payment-terms__link').addEventListener('click', function (e) {
        e.preventDefault()

        const paymentTermsContent = document.querySelector('.payment-terms-modal-content')
        if (paymentTermsContent) {
          const modal = new Modal({ class: 'modal-payment-terms' })
          modal.setContent(paymentTermsContent)
          modal.open()

          document.querySelector('.modal-payment-terms .close').addEventListener('click', function () {
            modal.close()
          })
        }
      })
    }
  },

  updatePaymentTermsModal: function (data) {
    const self = this
    const modal = document.querySelector('.payment-terms-modal-content')

    modal.querySelector('.recap__amount .amount__amount').innerHTML = common.formatCurrency(data.totalAmount)
    modal.querySelector('.recap__dates .date__from').innerHTML = common.formatDate(data.minCheckInDate)
    modal.querySelector('.recap__dates .date__to').innerHTML = common.formatDate(data.maxCheckOutDate)

    const cb2TimesClassName = '.payment-terms--cb-2-times'
    const cb3TimesClassName = '.payment-terms--cb-3-times'
    const pledg4TimesClassName = '.payment-terms--pledg-4-times'
    const bookNow3TimesClassName = '.payment-terms--book-now-3-times'
    const bookNow4TimesClassName = '.payment-terms--book-now-4-times'

    modal.querySelector(cb2TimesClassName).classList.remove('payment-terms--available')
    modal.querySelector(cb3TimesClassName).classList.remove('payment-terms--available')
    modal.querySelector(pledg4TimesClassName).classList.remove('payment-terms--available')
    modal.querySelector(bookNow3TimesClassName).classList.remove('payment-terms--available')
    modal.querySelector(bookNow4TimesClassName).classList.remove('payment-terms--available')

    if (data.paymentTerms['cb-2x']) {
      self.updatePaymentTermsList(modal, data.paymentTerms['cb-2x'], data.paymentTermsWithInsurance['cb-2x'], 'cb', cb2TimesClassName)
    }
    if (data.paymentTerms['cb-3x']) {
      self.updatePaymentTermsList(modal, data.paymentTerms['cb-3x'], data.paymentTermsWithInsurance['cb-3x'], 'cb', cb3TimesClassName)
    }
    if (data.pledgPaymentTerms['cb-4x']) {
      self.updatePaymentTermsList(modal, data.pledgPaymentTerms['cb-4x'], data.pledgPaymentTermsWithInsurance['cb-4x'], 'pledg', pledg4TimesClassName)
    }
    if (data.paymentTerms['3x-1e']) {
      self.updatePaymentTermsList(modal, data.paymentTerms['3x-1e'], data.paymentTermsWithInsurance['3x-1e'], 'cb', bookNow3TimesClassName)
    }
    if (data.paymentTerms['4x-1e']) {
      self.updatePaymentTermsList(modal, data.paymentTerms['4x-1e'], data.paymentTermsWithInsurance['4x-1e'], 'cb', bookNow4TimesClassName)
    }
  },

  updatePaymentTermsList: function (modal, paymentTerms, paymentTermsWithInsurance, psp, className) {
    const paymentTermsList = modal.querySelector(className)

    paymentTermsList.classList.add('payment-terms--available')

    for (let i = 0; i < paymentTerms.length; i++) {
      const paymentTermSelector = '.payment-term:nth-child(' + (i + 1) + ')'
      const paymentTerm = paymentTermsList.querySelector(paymentTermSelector)

      if (paymentTerm) {
        paymentTerm.querySelector('.payment-term__amount').innerHTML = common.formatCurrency(paymentTerms[i].amount)

        if (psp === 'pledg') {
          const insuranceAmountElement = paymentTerm.querySelector('.payment-term__line--insurance .payment-term__amount')

          if (insuranceAmountElement) {
            insuranceAmountElement.innerHTML = common.formatCurrency(paymentTermsWithInsurance[i].amount)
          }

          const dateElement = paymentTerm.querySelector('.date__date')

          if (dateElement) {
            dateElement.innerHTML = common.formatDate(paymentTerms[i].date)
          }
        } else if (i === 0) {
          paymentTerm.querySelector('.payment-term__line--insurance .payment-term__amount').innerHTML = common.formatCurrency(paymentTermsWithInsurance[i].amount)
        } else {
          paymentTerm.querySelector('.date__date').innerHTML = common.formatDate(paymentTerms[i].date)
        }
      }
    }
  }
}

module.exports = cart.init()
